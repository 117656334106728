var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Table__controls"},[_c('div',[_c('div',{staticClass:"pagination-wrapper"},[_c('div',[_vm._v(" Show ")]),_c('div',{staticClass:"pagination-perPage-select mx-1"},[_c('InputDropdown',{attrs:{"options":[
                10,
                25,
                50,
                100,
                500,
                1000,
                ],"value":_vm.limit,"allow-empty":false,"close-on-select":""},on:{"input":function($event){return _vm.$emit('setLimit', $event)}}})],1),_c('div',[_vm._v(" entries ")])])]),_c('div',[_c('div',{staticClass:"pagination-info-wrapper"},[(_vm.count && _vm.limit)?_c('div',[_vm._v(" Showing "+_vm._s(_vm.first)+" to "+_vm._s(_vm.last)+" of "+_vm._s(_vm.count)+" entries ")]):_vm._e()])]),_c('div',[_c('Paginate',{attrs:{"containerClass":"pagination","page-count":_vm.pages,"value":_vm.page},on:{"input":function($event){return _vm.selectPage($event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }