<template>
  <div class="Table__controls">
    <div>
      <div class="pagination-wrapper">
        <div>
          Show
        </div>
        <div class="pagination-perPage-select mx-1">
          <InputDropdown
              :options="[
                  10,
                  25,
                  50,
                  100,
                  500,
                  1000,
                  ]"
              :value="limit"
              :allow-empty="false"
              @input="$emit('setLimit', $event)"
              close-on-select
          />
        </div>
        <div>
          entries
        </div>
      </div>
    </div>

    <div>
      <div class="pagination-info-wrapper">
        <div v-if="count && limit">
          Showing {{ first }} to {{ last }} of {{ count }} entries
        </div>

      </div>
    </div>

    <div>
      <Paginate
          containerClass="pagination"
          :page-count="pages"
          :value="page"
          @input="selectPage($event)"
      />
    </div>
  </div>
</template>

<script>
import InputDropdown from '@/components/Inputs/InputDropdown.component';
import Paginate from 'vuejs-paginate';

export default {
  name: 'DDMCAF-TableControls',
  components: {
    InputDropdown,
    Paginate,
  },
  props: {
    count: {type: Number},
    limit: {type: Number},
    offset: {type: Number},
  },
  computed: {
    page() {
      return this.limit ? this.offset / this.limit + 1 : 1;
    },
    pages() {
      return this.count && this.limit ? Math.ceil(this.count / this.limit) : 1;
    },
    last() {
      return Math.min(this.count, this.offset+this.limit);
    },
    first() {
      return this.offset + 1
    },
  },
  emits: ['setLimit', 'selectPage'],
  methods: {
    selectPage(page) {
      this.$el.parentElement.scrollIntoView();
      this.$emit('selectPage', page);
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.Table__controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;

    .pagination-perPage-select {
      display: flex;
      align-items: center;
      justify-content: center;

      .InputDropdown {
        margin: 0;

        .multiselect {
          margin: 0;
        }
      }
    }
  }

  .pagination-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 43px;
  }

  .pagination {
    a {
      outline: none;
      font-size: 12px;
      font-weight: bold;
      color: #AFB1BC;
      user-select: none;
      padding: 12px;
    }

    li:first-child a {
      border-bottom-left-radius: 12px;
      border-top-left-radius: 12px;
    }

    li:last-child a {
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
    }

    .active a {
      background-color: $secondary-color;
      border-color: $secondary-color;
      font-weight: bold;
      color: white;
    }
  }
}
</style>
